import React, {Component} from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Hero from "../../components/hero";
import {withTranslation} from "react-i18next";

class IndexPagePl extends Component {

  render() {
    const {t} = this.props;

    return (
      <Layout>
        <Hero center header={t("header")} secondary={t("secondaryHeader")}/>
        <SEO title={t("siteTitle")}/>
      </Layout>
    );
  }

}

export default withTranslation()(IndexPagePl);
